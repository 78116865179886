// import '@fortawesome/fontawesome-pro/css/all.css';

// override styles
import '@/styles/vuetify-overrides.scss';
import router from '@/router';
import store from '@/store';

import Vue from 'vue';
import Vuetify, {
    VSnackbar,
    VIcon,
    VBtn,
} from 'vuetify/lib';
import VuetifyToast from 'vuetify-toast-snackbar';

Vuetify.config.silent = true;

Vue.use(Vuetify, {
    components: {
        VSnackbar,
        VIcon,
        VBtn,
    },
});

const vuetify = new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        dark: false,
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#19acd6',
                secondary: '#424242',
                accent: '#d21776',
                'accent-1': '#d21776',
                'accent-2': '#eeab39',
                error: '#d21776',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#eeab39',

                act: '#212121',
                inact: '#8e8e93',
                disabled: '#c7c7c7',

                'decoration-top': '#212121',

                'light': '#ffffff',
                'dark': '#333333',
                'off-white': '#f0f0f0',
            },
            dark: {
                primary: '#19acd6',
                secondary: '#424242',
                accent: '#d21776',
                'accent-1': '#d21776',
                'accent-2': '#eeab39',
                error: '#d21776',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#eeab39',

                act: '#ffffff',
                inact: '#d4d4d4',
                disabled: '#bbbbbb',

                'decoration-top': '#212121',

                'light': '#333333',
                'dark': '#ffffff',
                'off-white': '#333333',
            },
        },
    },
});

import VuetifyDialog from 'vuetify-dialog';
import 'vuetify-dialog/dist/vuetify-dialog.css';
Vue.use(VuetifyDialog, {
    context: {
        vuetify,
        // i18n,
        router,
        store,
    },
});

import '@/styles/vuetify-snackbar-customize.scss';
Vue.use(VuetifyToast, {
    $vuetify: vuetify.framework,
    x: 'center', // default
    y: 'bottom', // default
    color: 'info', // default
    icon: 'mdi-information',
    iconColor: '', // default
    classes: [
        'body-2',
    ],
    contentClass: 'test',
    timeout: 5000, // default
    dismissable: true, // default
    multiLine: false, // default
    vertical: false, // default
    queueable: false, // default
    showClose: false, // default
    closeText: 'Close', // default
    closeIcon: 'mdi-close', // default
    closeColor: '', // default
    slot: [], //default
    shorts: {
        custom: {
            color: 'purple',
        },
    },
    property: '$toast', // default
});

export default vuetify;
