import api from '@/api_helper';

const baseUrl = process.env.VUE_APP_RECRUIT_API;

/**
 * 홍보 페이지 URL을 생성하거나 이미 생성된 URL을 반환 받는다.
 *
 * @param userId {string} 생성요청한 user의 id
 * @param typeStr {string} 생성하고자 하는 타입의 식별 string (expert, mall)
 * @param [verbose=false] {boolean}
 * @return result {Result}
 * */
export const generatePage = async (userId, typeStr, verbose) => {
    try {
        if (!userId || !typeStr) throw new Error('필수값(userId or typeStr)이 빠졌음');

        return await api.post(`recruiter/page-url`, {
            user_id: userId,
            page_form: typeStr,
        }, {baseURL: baseUrl}).then(res => {
            return verbose ? res : res?.data?.url || res?.data || res
        })
    } catch (e) {
        console.log(e);
        throw e;
    }
}

/**
 * Url path(해시)의 생성자 및 타입 정보를 반환받는다.
 *
 * @param hashValue {string} 조회하고자 하는 path 해시정보
 * @param [verbose=false] {boolean}
 * @return result {Result}
 * */
export const getPathInfo = async (hashValue, verbose) => {
    try {
        if (!hashValue) throw new Error('필수값(hashValue)이 빠졌음');

        return await api.get(`recruiter/${hashValue}`, {baseURL: baseUrl}).then(res => {
            return verbose ? res : res?.data || res
        })
    } catch (e) {
        throw e;
    }
}

/**
 * 스페이스홈 신청 정보를 등록한다.
 *
 * @param data {{page_id: string, nickname: string, image_urls: (string[]), description: string, phone_number: string, sns_info: (string[]), email: string, space_home_pathname: string}} 등록할 정보 data set
 * @param [verbose=false] {boolean}
 *
 *
 * @return result {Result}
 * */
export const postRequestForm = async (data, verbose) => {
    try {
        return await api.post(`recruited-user`, data, {baseURL: baseUrl}).then(res => {
            return verbose ? res : res?.data || res
        })
    } catch (e) {
        throw e;
    }
}