export const checkMobileOS = () => {
    const ua = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

    if (ua.indexOf('android') > -1) {
        //안드로이드
        return "android";
    } else if ( ua.indexOf("iphone") > -1||ua.indexOf("ipad") > -1||ua.indexOf("ipod") > -1 ) {
        //IOS
        return "ios";
    } else {
        //아이폰, 안드로이드 외
        return "other";
    }
}

/**
 * @return {'macOS' | 'ios' | 'windows' | 'android' | 'linux'} - 사용자의 OS, linux는 추정.
 * */
export const checkOS = () => {
    let userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'macOS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'ios';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'windows';
    } else if (/Android/.test(userAgent)) {
        os = 'android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'linux';
    }

    return os;
}

export const isMobile = () => (/iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase()));

export default isMobile;