<template>
    <div class="tag-input-manager">
        <v-combobox ref="tagInput" :label="label" append-icon="fal fa-plus-square"
                    :placeholder="placeholder"
                    v-model="items"
                    v-if="isEditable"
                    :hint="hint || '엔터 키 혹은 콤마(,), 세미콜론(;)으로 등록'"
                    :persistent-hint="persistentHint"
                    :item="items" multiple
                    :rules="rules"
                    :autofocus="isEditable && submitWithBlur"
                    :search-input.sync="search"
                    @keydown="checkKey"
                    :counter="limit"
                    :single-line="singleLine"
                    :counter-value="countValue"
                    :append-outer-icon="toggleAvailable ? submitIcon : ''"
                    :loading="loading"
                    :disabled="loading"
                    :error-messages="errorMessages"
                    :tabindex="tabindex ? tabindex : false"
                    @blur="isEditable && submitWithBlur ? onSubmit() : false"
                    @click:append-outer="onSubmit"
                    @click:append="appendTag">
            <template v-slot:selection="data">
                <v-chip
                    class="tag-chip"
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    small
                    close close-icon="mdi-close"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    @click:close="removeTag(data.item)"
                >
                    {{ data.item }}
                </v-chip>
            </template>
        </v-combobox>

        <article v-else>
            <v-chip class="tag-chip ma-1" v-for="(tag, idx) in tags" small
                    :key="idx">{{ tag }}
            </v-chip>
            <v-btn v-if="toggleAvailable" class="ml-1" icon outlined x-small
                   @click="isEditable = true">
                <v-icon small>fal fa-plus</v-icon>
            </v-btn>
        </article>
    </div>
</template>

<script>
export default {
    name: 'TagInput',
    model: {
        props: 'tags',
        event: 'change',
    },
    props: {
        value: {
            type: Array,
            default: () => ([]),
        },
        tags: {
            type: Array,
            default: () => ([]),
        },
        limit: {
            type: Number,
            default: 0,
        },
        maxTagLength: {
            type: Number,
            default: 0,
        },

        label: {
            type: String,
            default: 'Tags',
        },
        singleLine: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        hint: {
            type: String,
            default: '',
        },
        persistentHint: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },

        toggleAvailable: {
            type: Boolean,
            default: false,
        },

        submitIcon: {
            type: String,
            default: 'fal fa-pencil',
        },

        submitWithBlur: {
            type: Boolean,
            default: false,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        tabindex: {
          type: [String, Number],
          default: 0,
        },

        onError: {
            type: Function,
            default: null,
        },
        errorMessages: {
            type: Array,
            default: () => [],
        }
    },
    watch: {
        tags(val) {
            this.items = Object.assign([], val, this.value);
        },

        items(val, oldVal) {
            this.checkLimit();
            this.$emit('change', val);
        },

        readonly(val) {
            this.isEditable = !val;
        },
        isEditable(val) {
            if (!val) {
                this.items = Object.assign([], this.tags);
            }

            this.$emit('update:readonly', !val);
        },
    },
    data: (vm) => ({
        items: [],
        search: '',
        rules: [
            v => !!v || 'test',
        ],
        // rules: [
        //     // v => v.length > 0 || '이건 아니야!!!',
        //     v => v.length < 0 && v.forEach(item => {
        //         return item.includes('error');
        //     }) || '에러임'
        // ],
        isEditable: true,
        submitListener: vm.$listeners.submit ? async (ev) => {
            await vm.$listeners.submit(ev);
        } : false,
    }),

    methods: {
        appendTag() {
            if (!this.search || this.search.trim() === '') {
                return false;
            }

            const check = this.items.find(o => {
                return o === this.search;
            });
            if (check) {
                return false;
            }

            this.items.push(this.search.trim());
            this.search = '';
        },

        removeTag(tag) {
            this.$refs.tagInput.selectItem(tag);
        },

        checkKey(ev) {
            const key = ev.key;

            this.search = this.search ? this.search.trim() : '';

            if (/([,; ])/g.test(key)) { // forbidden characters
                ev.preventDefault();
            }

            if (this.search && this.maxTagLength && this.search.length > this.maxTagLength) {
                ev.preventDefault();
                this.search = this.search.slice(0, this.maxTagLength);

                const err = new Error(`Tag length dose not overed ${this.maxTagLength}`);
                throw err;
            }

            if (this.search && /([,; ])/g.test(key)) { // input trigging characters
                this.appendTag();
            }
        },

        checkLimit() {
            const tags = this.items;
            const limit = this.limit;

            if (limit && tags.length > limit) {
                this.items = tags.slice(0, 10);
                throw new Error('Maximum 10 Tags');
            }
        },

        countValue() {
            return '0/10 Tags';
        },

        async onSubmit(ev) {
            // this.$emit('submit', this.items);
            if (this.submitListener) {
                await this.submitListener(this.items);
            }

            this.isEditable = false;
        },
    },

    mounted() {
        this.items = Object.assign([], this.tags, this.value);
        this.isEditable = !this.readonly;
    },

    errorCaptured(err) {
        console.log('errorCaptured!!!')
        if (this.onError) {
            this.onError(err);
            return false;
        }

        this.$emit('onError', err);
    },
};
</script>

<style lang="scss">
/*
.tag-chip.v-chip--no-color {
  border: 1px solid #ccc;
}
*/
.tag-input-manager {
  .v-text-field--single-line {
    margin-top: -12px;
  }
}
</style>
