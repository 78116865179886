<template>
    <v-container tag="section" :data-is-mobile="isMobile">
        <v-row justify="center" v-if="!isSummited">
            <v-col cols="12" sm="9">
                <div class="form-header mb-15">
                    <h2 class="text-center">지금 당신도 늦기 전에 답변톡을 도입하세요.</h2>
                </div>

                <v-form @submit.prevent="formSubmit" v-model="isFormValid" ref="form">
                    <v-text-field label="이메일 주소" type="email" placeholder="계정으로 사용될 이메일 주소를 적어주세요."
                                  :validate-on-blur="true" :rules="emailRules"
                                  v-model="email" :tabindex="1"/>

                    <v-text-field label="핸드폰 번호" type="tel" placeholder="핸드폰 번호를 입력해주세요." v-model="phoneNumber"
                                  :validate-on-blur="true" :rules="phoneNumberRules" :tabindex="2" />

                    <v-text-field label="닉네임" type="text" placeholder="닉네임(3~10글자, 한글, 영문, 숫자만 사용가능)"
                                  v-model="nickname" :validate-on-blur="true"
                                  :rules="[
                                        v => !!v || '닉네임은 반드시 등록해주셔야 합니다.',
                                        v => /^(?=.*[a-zA-Z0-9가-힣])[a-zA-Z0-9가-힣]{3,10}$/.test(v) || '닉네임은 3~10글자 이내의 한글, 영문, 숫자만 사용가능합니다.'
                                    ]" :tabindex="3"/>

                    <v-text-field prefix="https://flowing.space/" label="내 주소" type="text"
                                  v-model="urlPath"
                                  placeholder="4~20자, 영문 및 숫자, 특수문자(-, _)가능"
                                  :rules="spaceUriRules" :tabindex="4"/>

                    <v-text-field label="PR 및 소개문구" type="text" placeholder="PR 및 소개문구" v-model="description" :rules="[
                        v => !!v || '필수 입력사항입니다.'
                    ]" :tabindex="5"/>

                    <SiteInput label="유튜브, 인스타, 홈페이지 및 기타 SNS 주소" placeholder="유튜브, 인스타, 홈페이지, 기타 SNS 주소(쉼표, 공백, 세미콜론으로 구분하여 추가 입력)"
                               v-model="snsUrls" :error-messages="siteErrors" :limit="10" :max-tag-length="0" :tabindex="6"/>

                    <v-file-input label="사진이나 이미지" prepend-icon="" prepend-inner-icon="mdi-image" counter
                                  ref="fileInput"
                                  multiple show-size small-chips
                                  truncate-length="14"
                                  :loading="isFileLoading"
                                  :rules="fileInputRules"
                                  :error-messages="fileInputErrors"
                                  hint="클릭하여 파일첨부 (여러 파일 동시첨부 가능)"
                                  :persistent-hint="true"
                                  accept="image/*"
                                  @click:prepend-inner="() => {this.$refs.fileInput.$refs.input.click()}"
                                  @change="changeImages" :tabindex="7"/>

                    <div class="d-flex justify-center mt-5 p-7">
                        <v-btn color="primary" type="submit" :disabled="!isFormValid || isFileLoading" :loading="isFormLoading" x-large>스페이스홈 개설 요청하기</v-btn>
                    </div>
                </v-form>

                <div class="footer">
                    <article>
                        <h2 class="text-center">해당 내용을 보내 주시면 답변톡을 개설하여 바로 보내드립니다.</h2>
                        <div class="description">
                            * 답변톡이 개설되면 비번 변경 후 사용 가능합니다.
                        </div>
                    </article>
                </div>

            </v-col>
        </v-row>

        <v-row v-else>
            <v-col>
                <div class="footer">
                    <article>
                        <h2 class="text-center">성공적으로 등록이 완료되었습니다!</h2>
                        <div class="description">
                            답변톡을 개설 후, 연락드리겠습니다.
                        </div>
                        <div class="d-flex justify-center">
                            <v-img :style="{marginLeft: '-5%'}" :src="require('@/assets/images/flowing/character/hi-pudding.gif')" max-width="40%" />
                        </div>
                    </article>
                </div>
            </v-col>
        </v-row>



        <v-row justify="center">
            <v-col cols="12" sm="9">
                <v-row class="link-area" justify="center">
                    <v-col cols="10" sm="4">
                        <a class="link-box" href="https://flowing.space" target="_blank">
                            <div>사이트 둘러보기</div>
                            <v-icon color="primary">mdi-arrow-right-box</v-icon>
                        </a>
                    </v-col>
                    <v-col cols="10" sm="4">
                        <a class="link-box" :href="computedAppLink" target="_blank">
                            <div>앱 설치하고</div>
                            <v-icon color="primary">mdi-arrow-right-box</v-icon>
                        </a>
                    </v-col>
                    <v-col cols="10" sm="4">
                        <a class="link-box" href="https://console.flowing.space" target="_blank">
                            <div>콘솔 바로가기</div>
                            <v-icon color="primary">mdi-arrow-right-box</v-icon>
                        </a>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {checkMobileOS, checkOS} from '@/utils/checkMobile';

import {postRequestForm} from '@/services/recruit.service';
import {uploadImages} from '@/services/proxy.service';
import SiteInput from '@/components/SiteInput';

export default {
    name: 'Form',
    components: {
        SiteInput,
    },
    props: {
        isMobile: {
            type: Boolean,
            default: false,
        },
        pageId: {
            type: String,
            default: '',
        },
        pageType: {
            type: String,
            default: 'type1',
        },
    },

    watch: {
        snsUrls: {
            // urlsRules: this.urlsRules,
            handler(val, oldVal) {
                const results = [];
                const rules = this.urlsRules;
                if (val.length === oldVal.length && val.length === 0) {
                    return false;
                }

                rules.forEach(rule => {
                    if (typeof rule !== 'function') {
                        return false;
                    }
                    if (rule(val) !== true) {
                        results.push(rule(val));
                    } else {
                        console.log('rule(val)', rule(val));
                    }
                    console.log('results', results);

                    this.siteErrors = results;
                });
            },
            deep: true,
        },
    },

    computed: {
        computedAppLink() {
            const os = checkOS();
            const appleLink = 'https://apps.apple.com/kr/app/%ED%94%8C%EB%A1%9C%EC%9E%89/id1457920637',
                androidLink = 'https://play.google.com/store/apps/details?id=tpkorea.com.flowing&pli=1'

            switch(os) {
                case 'linux':
                case 'windows':
                case 'android': return androidLink;
                case 'macOS':
                case 'ios': return appleLink;
                default: return androidLink;
            }
        },
    },

    data: (vm) => ({
        isFormValid: false,

        api: process.env.VUE_APP_RECRUIT_API,
        proxyApi: process.env.VUE_APP_PROXY_API,
        buildEnv: process.env.NODE_ENV,
        deployEnv: process.env.VUE_APP_DEPLOY_ENV,

        email: '',
        emailRules: [
            v => !!v || '이메일 계정은 필수 입력사항입니다.',
            v => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ? true : '이메일 포멧에 맞게 입력해주세요.',
        ],

        phoneNumber: '',
        phoneNumberRules: [
            v => !!v || '핸드폰 번호는 필수 입력사항입니다.',
            v => /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/.test(v) || '올바른 전화번호가 아닙니다.',
        ],

        nickname: '',
        description: '',

        urlPath: '',
        spaceUriRules: [
            v => !!v || '사용하고 싶은 스페이스홈 주소를 입력해주세요.',
            v => /^[a-z0-9-/_]{4,20}$/.test(v) || '사용할 수 없는 주소입니다.',
        ],

        snsUrls: [],
        siteErrors: [],
        urlsRules: [
            v => v.length > 0 || '하나 이상의 사이트를 등록해 주세요.',
            v => v.length <= 10 || '최대 10개까지만 입력 가능합니다.',
            v => vm.validateUrls(v) || 'Url 형식에 맞지 않는 주소가 포함되어 있습니다.',
        ],

        isFileLoading: false,
        maxFileNum: 10,
        fileInputRules: [
            v => v?.length > 0 || '한 개 이상의 이미지를 첨부해주세요.',
            v => v?.length <= vm.maxFileNum || `최대 ${vm.maxFileNum}개의 이미지를 첨부하실 수 있습니다.`,
        ],
        fileInputErrors: [],
        fileResUrl: [],

        isFormLoading: false,
        isSummited: false,

        links: [
            {
                label: '사이트 둘러보기',
                href: 'https://flowing.tel'
            },
            {
                label: '앱 설치하고',
                href: 'https://flowing.tel'
            },
            {
                label: '콘솔 바로가기',
                href: 'https://console.flowing.tel'
            }
        ],
    }),

    methods: {
        compositeFormData() {
            return {
                page_id: this.pageId || '',
                nickname: this.nickname || '',
                phone_number: this.phoneNumber || '',
                email: this.email || '',
                sns_info: this.snsUrls || [],
                image_urls: this.fileResUrl || [],
                description: this.description || '',
                space_home_pathname: this.urlPath || '',
            }
        },

        clearFormData() {
            // this.pageId = '';
            // this.nickname = '';
            // this.phoneNumber = '';
            // this.email = '';
            // this.snsUrls = [];
            // this.fileResUrl = [];
            // this.description = '';
            // this.urlPath = '';
            this.isSummited = true;
        },
        
        async formSubmit() {
            this.isFormLoading = true;

            if (!this.isFormValid) return false; 
            try {
                this.$gtag.event('formSubmit', this.$route.params);

                const formData = this.compositeFormData();
                const result = await postRequestForm(formData);

                console.log('%c User Form Data Posted', 'background:rgba(0,255,0,0.5);color: green;', result);
                
                this.$toast.success('요청이 성공적으로 등록되었습니다.');
                this.clearFormData();
            } catch (e) {
                this.$toast.error('요청이 실패하였습니다. 다시 시도해주세요.');
                console.error(e);
            } finally {
                this.isFormLoading = false;
            }
        },

        validateUrls(urls) {
            try {
                console.log('validateUrls');
                urls = urls || [];

                let test = true;
                urls.forEach(url => {
                    const matched = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(url);
                    if (matched === false) test = matched;
                });

                console.log(test, urls)
                return test;
            } catch (e) {
                throw e;
            }
        },

        async changeImages(files) {
            this.fileResUrl = [];
            this.fileInputErrors = [];

            this.isFileLoading = true;

            try {
                const rules = this.fileInputRules || [];
                let isValid;
                rules.forEach((rule, idx) => {
                    if (typeof rule === 'function' && rule(files) !== true) {
                        isValid = false;
                    }
                });
                if (isValid === false) {
                    return false;
                }

                const uploadedFiles = await uploadImages(files);

                console.log('uploadedFiles', uploadedFiles);
                const urls = [...uploadedFiles?.url];

                console.log(urls);

                this.fileResUrl = urls;
                console.log('파일 업로드 가동됨-2');
            } catch (e) {
                this.fileResUrl = [];
                if (e?.data?.place_holder || e?.place_holder) {
                    const errorSet = [];
                    errorSet.push(e?.data?.place_holder || e?.place_holder);

                    this.fileInputErrors = errorSet;
                } else {
                    throw e;
                }
            } finally {
                this.isFileLoading = false;
            }
        },

        errorCaptured(err) {
            console.log(err)
        },
    },
};
</script>

<style lang="scss" scoped>
*:not([data-is-mobile]) .form-header {
  h2 {
    font-size: 1.8rem;

    @media screen and (max-width: 550px) {
      font-size: 1.3rem;
    }
  }
}

[data-is-mobile] .form-header {
  h2 {
    font-size: 1.3rem;
  }
}

.footer {
  margin: 4rem 0;
  text-align: center;
}

.link-area {
  //margin: 4rem 0;
  margin-bottom: 4rem;

  .link-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 10px 25px;
    color: #333;
    text-decoration: none;
    height: 100%;

    &:not([href]) {
      cursor: default;
    }
  }
}
</style>
