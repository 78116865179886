import api from '@/api_helper';

const deploymentEnvironment = process.env.VUE_APP_DEPLOY_ENV;
const baseUrl = process.env.VUE_APP_PROXY_API;

/**
 * image를 버킷에 업로드하고 업로드 정보를 반환받는다.
 *
 * @param files {file[], file} file 객체 - array 혹은 단일 객체
 * @param verbose {boolean}
 * @return result {Result}
 * */
export const uploadImages = async (files = [], verbose = false) => {
    try {
        const formData = new FormData();
        let err;

        files.forEach(item => {
            const fileType = item?.type?.substring(0, 5);
            if (fileType !== 'image') {
                err = new Error('이미지 파일만 업로드 할 수 있습니다.');
                err.place_holder = '이미지 파일만 업로드 할 수 있습니다.';
                throw err;
            }

            const fileSize = item?.size / (1024*1024);
            if (fileSize > 20) {
                err = new Error('20MB를 초과하는 이미지는 업로드 할 수 없습니다.');
                err.place_holder = '20MB를 초과하는 이미지는 업로드 할 수 없습니다.';
                throw err;
            }

            formData.append('upload', item);
        })

        formData.append('limitedImageSize', 2560);

        return await api.post(`common/files/upload/flowing-web-resources/image?env=${deploymentEnvironment.toUpperCase() || 'DEV'}`, formData, {baseURL: baseUrl}).then(res => {
            return verbose ? res : res?.data || res
        })
    } catch (e) {
        throw e;
    }
}