import Vue from 'vue';

Vue.config.errorHandler = (err, vm, info) => {
    try {
        const place_holder = err?.data?.place_holder || err?.place_holder;

        if (place_holder) {
            vm.$toast.error(place_holder, {
                closeIcon: 'mdi-close', // default
            });

            return false;
        }

        vm.$toast.error(err.message, {
            closeIcon: 'mdi-close', // default
        });
    } finally {
        console.error(err);
    }
};

window.onerror = function(message, source, lineno, colno, error) {
    console.error(error);

    return true;
}
