import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// 배포환경에 따른 로그 출력 Override
const env = process.env.VUE_APP_DEPLOY_ENV || 'dev'

// setting analytics
import VueGtagPlugin from 'vue-gtag';

Vue.use(VueGtagPlugin, {
  config: {
    id: env === 'prd' ? process.env.VUE_APP_ANALYTICS_ID : '',
  }
})

import '@/errorHandler'

Vue.config.productionTip = false

if(env !== 'dev'){
  if(!window.console) window.console = {};
  const methods = ["log", "debug", "warn", "info"]
  for(let i=0; i<methods.length; i++){
    console[methods[i]] = function(){};
  }
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
