import Vue from 'vue';
import VueRouter from 'vue-router';
import checkMobile from '@/utils/checkMobile';
import { getPathInfo } from '@/services/recruit.service';

import FormView from '../views/FormView.vue';
import DesktopLayOut from '@/layouts/DesktopLayout';
import MobileLayout from '@/layouts/MobileLayout';

import Generator from '@/views/Generator';

const PCComponent = () => import(/* webpackChunkName: "px-content" */ '@/views/DesktopContentWrapper')
const MobileComponent = () => import(/* webpackChunkName: "mobile-content" */ '@/views/MobileContentWrapper')

Vue.use(VueRouter);

const routes = [
    // {
    //     path: '/generator',
    //     name: 'PathGenerator',
    //     component: Generator,
    // },
    {
        path: '/:hashKey',
        name: 'RecruitRoot',
        component: checkMobile() ? MobileLayout : DesktopLayOut,
        children: [
            {
                path: '',
                name: 'RecruitView',
                props: {
                    default: true,
                    pageContent: true,
                },
                components: {
                    pageContent: checkMobile() ? MobileComponent : PCComponent,
                    default: FormView
                },
            }
        ],
        async beforeEnter(to, from, next) {
            try {
                const params = to.params;

                const pathInfo = await getPathInfo(params.hashKey);

                if (!pathInfo) {
                    next({
                        name: 'NotFound',
                        params: {
                            0: to.path,
                        },
                    });
                }

                params.pageId = pathInfo?.page_id;
                params.pageType = pathInfo?.page_form;
                params.isMobile = checkMobile();

                next();
            } catch (e) {
                const status = e?.status || e?.response?.status || 404;

                next({
                    name: status === 404 ? 'NotFound' : 'Errors',
                    params: {
                        0: to.path,
                        status: status,
                    },
                });
            }
        },
    },
    {
        path: '*',
        name: 'Errors',
        component: () => import(/* webpackChunkName: "errors" */ '../views/Errors'),
        props: true,
    },
    {
        path: '*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "errors" */ '../views/Errors/404.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
