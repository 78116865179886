<template>
    <v-container class="fill-height off-white" fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="6" md="4">

                <v-container fluid class="d-flex flex-wrap flex-column justify-center align-center">
                    <div class="mb-5">Generated Url: {{generatedUrl || '????'}}</div>
                    <v-btn :loading="isLoading" color="black" dark @click="generatePath">Generate Url</v-btn>
                </v-container>

            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped>
</style>

<script>
import { generatePage } from '@/services/recruit.service';

export default {
    name: 'GeneratorPage',
    data: () => ({
        isLoading: false,
        generatedUrl: '',
    }),
    methods: {
        async generatePath() {
            this.isLoading = true;
            try {
                const url = await generatePage('8376132a-b53d-48dc-bea1-fb0c7031d9ab', 'group-buyer');

                this.generatedUrl = url;
                console.log('url', url);
            } catch (e) {
                this.$toast.error(e?.message);
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
    },
}
</script>